import React from 'react';
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_Resources } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/buttons/Button';
import { Link } from 'gatsby';
import moment from 'moment';
import useBlogPosts from '~/hooks/useBlogPosts';
import { getCookieLink } from '../../hooks/setCookies';

export default function Resources({
  section,
  name,
  category,
  uri,
  posts,
}: WpPage_FlexibleContent_FlexibleContent_Resources) {
  if (!category.posts) return null;

  const { allWpPost } = useBlogPosts();

  return (
    <div className="relative">
      <Section background={category.category.background} layoutName="Resources" id={category.name}>
        <div className="container space-y-8 md:space-y-20 relative z-[1]">
          <div className={`space-y-4 lg:px-24 ${category.slug === 'insights' && 'text-center'} `}>
            <FadeInWhenVisible>
              <h3 className="text-h3 text-h3--alt" dangerouslySetInnerHTML={{ __html: category.name }} />
            </FadeInWhenVisible>
            <FadeInWhenVisible>
              <Button
                link={{ url: category.link, title: 'View all' }}
                type={ButtonType.Text}
                color={ButtonColor.Black}
              />
            </FadeInWhenVisible>
          </div>
          {category.slug === 'insights' ? (
            <InsightsArticles category={category} allWpPost={allWpPost} />
          ) : (
            <Articles category={category} allWpPost={allWpPost} noCard={category.slug === 'renovations-improvements'} />
          )}
        </div>
      </Section>
      {category.background !== 'white' && <div className="absolute bottom-0 left-0 right-0 bg-white-off h-[30vh]" />}
    </div>
  );
}

export const Articles = ({ category, allWpPost, noCard }) => {
  return (
    <FadeInWhenVisible
      className={`${noCard ? 'lg:px-24' : 'bg-white resources-card p-8 py-12 lg:py-24 lg:p-24'}  relative z-10  ${
        category.background !== 'white' && 'resources-box -mb-20'
      }`}
    >
      <div className="grid gap-y-16 lg:gap-y-0 lg:grid-cols-2 lg:gap-x-16">
        <div className="">
          <>
            {allWpPost?.nodes
              ?.filter((wpPost) => wpPost?.id === category?.posts?.nodes[0]?.id)
              .map((item, i) => (
                <FeaturedArticle {...item} key={`featuredArticle${i}`} />
              ))}
          </>
        </div>
        <div className=" space-y-8">
          {category?.posts?.nodes?.slice(1, 4).map((post, i) => (
            <>
              {allWpPost?.nodes
                ?.filter((wpPost) => wpPost?.id === post?.id)
                .map((item, i) => (
                  <Article {...item} key={`articlesArticle${i}`} />
                ))}
            </>
          ))}
        </div>
      </div>
    </FadeInWhenVisible>
  );
};

export const InsightsArticles = ({ category, allWpPost }) => {
  return (
    <div className={`relative z-10 `}>
      <div className="">
        <div className="max-w-[995px] mx-auto space-y-12 md:space-y-24">
          <FadeInWhenVisible className="grid gap-y-12 lg:gap-y-0 lg:grid-cols-3 lg:gap-x-8">
            {category?.posts?.nodes?.slice(0, 2).map((post, i) => (
              <div key={`insightsArticleCategory${i}`}>
                {allWpPost.nodes
                  .filter((wpPost) => wpPost.id === post.id)
                  .map((item) => (
                    <ArticleAlt {...item} key={`insightsArticleAlt${i}`} />
                  ))}
              </div>
            ))}
          </FadeInWhenVisible>
          {allWpPost?.nodes
            ?.filter((wpPost) => wpPost?.id === category?.posts?.nodes[0]?.id)
            .map((item) => (
              <FadeInWhenVisible>
                <ArticleAltFeatured {...item} />
              </FadeInWhenVisible>
            ))}
        </div>
      </div>
    </div>
  );
};

function getUTMLink(url) {
  return getCookieLink(url || '');
}

export const FeaturedArticle = ({ title, uri, date, featuredImage, article }) => {
  return (
    <Link to={`${uri}${getUTMLink(uri)}`} className="space-y-7 text-center group">
      <div className="w-full">
        <div className="aspect-w-2 aspect-h-1 overflow-hidden">
          <Image
            image={featuredImage?.node}
            objectFit="cover"
            className="!absolute transform transition-transform duration-1000 ease-in-out group-hover:scale-110"
          />
        </div>
      </div>
      <div className="space-y-8">
        <div className="space-y-3.5">
          <h6 className="text-h3 text-h3--alt leading-[1.08]" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="text-[17px] leading-[1.55] font-display font-normal max-w-[431px] mx-auto">
            {article?.summary}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <ArticleInfo uri={uri} date={date} article={article} />
        </div>
      </div>
    </Link>
  );
};

export const Article = ({ title, uri, date, featuredImage, article }) => {
  return (
    <Link to={`${uri}${getUTMLink(uri)}`} className="group flex flex-col md:flex-row items-center md:space-x-4">
      <div className="flex-auto space-y-6">
        <div className="space-y-2">
          <h6 className="text-h6 text-h6--small !font-body" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="text-[15px] font-display">{article.summary}</p>
        </div>
        <ArticleInfo uri={uri} article={article} date={date} />
      </div>

      <div className="w-full mb-4 md:mb-0 md:w-32 ml-auto flex-shrink-0 order-[-1] md:order-[0]">
        <div className="aspect-w-1 aspect-h-1 overflow-hidden ">
          <Image
            image={featuredImage?.node}
            objectFit="cover"
            className="!absolute transform transition-transform duration-1000 ease-in-out group-hover:scale-110"
          />
        </div>
      </div>
    </Link>
  );
};

export const ArticleAlt = ({ title, uri, date, featuredImage, article }) => {
  return (
    <Link to={`${uri}${getUTMLink(uri)}`} className="group space-y-8 block">
      <div className="aspect-w-15 aspect-h-16 overflow-hidden ">
        <Image
          image={featuredImage?.node}
          objectFit="cover"
          className="!absolute transform transition-transform duration-1000 ease-in-out group-hover:scale-110"
        />
      </div>
      <div className="flex-auto space-y-6">
        <div className="space-y-2">
          <h6 className="text-h6 text-h6--small !font-body" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="text-[15px] font-display">{article.summary}</p>
        </div>
        <ArticleInfo uri={uri} article={article} date={date} />
      </div>
    </Link>
  );
};

export const ArticleAltFeatured = ({ title, uri, date, featuredImage, article }) => {
  return (
    <Link
      to={`${uri}${getUTMLink(uri)}`}
      className="group block space-y-8 md:space-y-0 md:grid grid-cols-2 items-start gap-x-12"
    >
      <div className="aspect-w-3 aspect-h-2 overflow-hidden ">
        <Image
          image={featuredImage?.node}
          objectFit="cover"
          className="!absolute transform transition-transform duration-1000 ease-in-out group-hover:scale-110"
        />
      </div>
      <div className="flex-auto space-y-6 pt-6">
        <div className="space-y-2">
          <h6 className="text-h3 text-h3--alt" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="text-[15px] font-display">{article.summary}</p>
        </div>
        <ArticleInfo uri={uri} article={article} date={date} />
      </div>
    </Link>
  );
};

export const ArticleInfo = ({ uri, date, article }) => {
  return (
    <div className="flex items-center space-x-3">
      <Button link={{ url: uri, title: 'Article' }} type={ButtonType.Text} color={ButtonColor.Black} />
      <div className="bg-black h-0.5 w-5"></div>
      <div className="flex items-center space-x-1">
        <span className="">{moment(date).format('MMM DD')}</span>
        {article?.readTime && (
          <>
            <div>/</div>
            <span className="text-grey">{article?.readTime}</span>
          </>
        )}
      </div>
    </div>
  );
};
