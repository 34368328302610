import React, { useState, useEffect } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Icon, IconType } from './Icon';

export default function SocialSharing({ className, label = false }) {
  const [pageUrl, setPageUrl] = useState('');
  useEffect(() => {
    setPageUrl(window?.location?.href);
  }, []);

  return (
    <div className="flex items-center space-x-3.5">
      {label && <div>Share</div>}
      <div className={`flex items-start space-x-2.5 `}>
        <FacebookShareButton url={pageUrl}>
          <div className="w-[42px] h-[42px] border border-blue rounded-full flex items-center justify-center group hover:bg-blue transition-colors duration-300 ease-in-out group">
            <div className="w-4 h-4 fw-icon text-navy-dark group-hover:text-white">
              <Icon type={IconType.Facebook}  />
            </div>
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={pageUrl}>
          <div className="w-[42px] h-[42px] border border-blue rounded-full flex items-center justify-center group hover:bg-blue transition-colors duration-300 ease-in-out group">
            <div className="w-4 h-4 fw-icon text-navy-dark group-hover:text-white">
              <Icon type={IconType.Twitter}   />
            </div>
          </div>
        </TwitterShareButton>
        <LinkedinShareButton url={pageUrl}>
          <div className="w-[42px] h-[42px] border border-blue rounded-full flex items-center justify-center group hover:bg-blue transition-colors duration-300 ease-in-out group">
            <div className="w-4 h-4 fw-icon text-navy-dark group-hover:text-white">
              <Icon type={'linkedin'} />
            </div>
          </div>
        </LinkedinShareButton>
      </div>
    </div>
  );
}
