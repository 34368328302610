import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import Layout from "~/components/Layout";
import { Section } from "~/components/elements/Section";
import moment from "moment";
import FlexibleContent from "~/components/FlexibleContent";
import { Icon, IconType } from "~/components/elements/Icon";
import {
  WpPage_Page_FlexibleContent,
  WpPost,
  WpPostFieldsEnum,
} from "~/_generated/types";
import useBlogPosts from "~/hooks/useBlogPosts";
import SearchResults from "~/components/global/SearchResults";
import Image from "~/components/elements/Image";
import SocialSharing from "~/components/elements/SocialSharing";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { ModalVideoWrapped } from "~/components/elements/ModalVideoWrapped";
import { Button, ButtonColor, ButtonType } from "../components/elements/buttons/Button";
import { ArticleAlt, ArticleInfo } from "../components/flexible/Resources";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'

export default function articlePage({
  data: { wpPage, wpPost, wp, siteSearchIndex },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const [query, setQuery] = useState("");

  const { allWpPost } = useBlogPosts();

  return (
    <Layout
      wp={wp}
      activeSearchQuery={query}
      setQuery={setQuery}
      headerColor="navy">
      <Seo post={wpPost} title={wpPost.title} />
      <DefaultHero wpPost={wpPost} />
      <Content wpPost={wpPost} />
      <Related articles={allWpPost} />
    </Layout>
  );
}

export const Content = ({ wpPost }) => (
  <Section>
    <div className="container">
      <div className="border-b border-b-navy-dark mb-12 md:mb-24 pb-8 flex items-center justify-between text-h6 !font-normal">
        <div className=""><CategoryLabel wpPost={wpPost} /></div>
        <time className="">{moment(wpPost.date).format("DD-MM-YY")}</time>
      </div>
      <div className="lg:grid grid-cols-12">
        <div className="col-span-4">
          <div className="space-y-10">
            {wpPost?.article?.summary && (
              <h5 className="text-h5 !font-normal !leading-[1.25]">
                {wpPost.article.summary}
              </h5>
            )}
            <div className="flex flex-col space-y-8">
              <div className="space-y-3">
                <div className="text-[14px] font-display font-light">
                  <CategoryLabel wpPost={wpPost} />
                </div>
                <div className="leading-[1]">{wpPost.title}</div>
              </div>
              <div>
                <time className="">{moment(wpPost.date).format("DD-MM-YY")}</time>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <SocialSharing />
          </div>
        </div>
        <div className="col-span-7 col-start-6 flex-auto flex-shrink-0 lg:ml-20 mt-12 lg:mt-0 article">
          {wpPost.content && (
            <div className="prose" dangerouslySetInnerHTML={{ __html: wpPost.content}} />
          )}

          <FlexibleContent
            content={wpPost?.article?.flexibleContent}
            prefix="Post_Article_FlexibleContent_"
          />
        </div>
      </div>
    </div>
  </Section>
);

export const DefaultHero = ({ wpPost }) => {
  return (
    <Section background="navy" layoutName={'ArticleHero'}>
      <div className="space-y-8 md:space-y-[56px]">
        <div className="container">
          <div className="lg:px-[7vw] space-y-8 md:space-y-12">
            <BackTo />
            <div className="space-y-6 md:space-y-10">
              <div className="space-y-5">
                <div className="!font-normal">
                  <Button link={{ url: '/', title: "Article" }} type={ButtonType.Text} color={ButtonColor.Black} />
                </div>
                <h1 className="text-h1 text-h1--article">{wpPost.title}</h1>
              </div>
              <div className="flex items-center space-x-8">
                <time className="inline-block opacity-50">
                  {moment(wpPost.date).format("Do MMMM YYYY")}
                </time>
                {wpPost.article.readTime && (
                  <div className="flex items-center space-x-2.5">
                    <div className="w-4 h-4 fw-icon text-current">
                      <Icon type={"clock"} />
                    </div>
                    <span className="block">{wpPost.article.readTime}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="container">
            <div className="aspect-w-16 aspect-h-9 z-10">
              <Image image={wpPost?.featuredImage?.node} className="!absolute" objectFit="cover" />
            </div>
            <div className="absolute top-[60%] bottom-0 left-0 right-0 bg-white-off "></div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const BackTo = () => {
  return (
    <Link to="/resources" className=" pl-2 group">
      <div className="-ml-2 inline-flex items-center space-x-2.5">
        <div className="fw-icon w-3 transition-transform group-hover:-translate-x-2 duration-300 ease-in-out">
          <Icon type={IconType.ArrowLeft} />
        </div>
        <span className="text-[15px] leading-none pt-0.5">Back to articles</span>
      </div>
    </Link>
  )
}

export const Video = ({ article }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="relative cursor-pointer group"
        onClick={() => setIsOpen(true)}
      >
        <Image image={article.video.image} />
        <div className="absolute inset-0 z-10 flex flex-col justify-center bg-black bg-opacity-70">
          <div className="flex items-center ml-8 md:ml-32">
            <div className="relative z-10 flex items-center justify-center bg-white rounded-full  w-28 h-28 ">
              <div className="absolute inset-0 transition-transform duration-300 ease-in-out bg-white rounded-full w-28 h-28 group-hover:scale-110" />
              <div className="absolute ml-1 transform -translate-x-1/2 -translate-y-1/2 w-7 fw-icon top-1/2 left-1/2">
                {/* <Icon type={IconType.Play} /> */}
              </div>
            </div>
            <div className="text-[21px] ml-3">Play video</div>
          </div>
        </div>
      </div>
      <ModalVideoWrapped
        channel="custom"
        autoplay={1}
        isOpen={isOpen}
        url={article?.video?.video?.localFile?.publicURL}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export const Related = ({ articles }) => {
  return (
    <Section className="" layoutName={'RelatedArticles'}>
      <div className="container space-y-20 md:space-y-40">
        <div className="border-t border-t-black border-opacity-20 pt-16 md:flex space-y-6 md:space-y-0 justify-between">
          <BackTo />
          <SocialSharing label />
        </div>
        <div className="max-w-[995px] mx-auto">
          <div className="md:grid grid-cols-3 space-y-8 md:space-y-0 gap-x-8">
            {articles?.nodes?.slice(0, 3).map((article, i) => (
              <ArticleAlt {...article} key={`relatedArticleAlt${i}`} />
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export const CategoryLabel = ({wpPost}) => {
  return (
    <>
     {wpPost?.terms?.nodes.filter(n => n.nodeType === 'Category').slice(0,1).map((item, i) => (
        <div key={`categoryLabel${i}`}>
          {item.name}
        </div>
      ))}
    </>
  )
}



export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
  }
`;
